<script>
import feather from 'feather-icons';
import ProjectsFilter from './ProjectsFilter.vue';
import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
	components: { ProjectSingle, ProjectsFilter },
	data: () => {
		return {
			projects,
			projectsHeading: '我的项目',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		/*
		计算属性（Computed Properties）在Vue.js中是指基于组件的其他数据（即依赖）动态计算出的新值。它们是Vue实例的一个特殊属性，其值由一个函数计算得出，并且这个函数会在相关依赖变化时自动重跑。
		何时使用计算属性：
			1. 数据处理：当你需要对Vue实例的数据进行转换或计算，比如格式化日期、过滤数组、合并多个数据项等。
			2. 派生状态：当一个属性的值依赖于其他一个或多个属性时，比如计算总和、平均值或条件结果。
			3. 避免重复的计算逻辑：如果你在模板中多次使用相同计算逻辑，计算属性可以确保这个逻辑只计算一次，并在后续使用时缓存结果。
			4. 响应式：计算属性自动响应依赖的变化，无需手动监听和更新。
			5. 提高性能：由于Vue会跟踪计算属性的依赖关系，并在依赖改变时自动更新，所以它们比在模板中直接使用方法更高效，因为方法在每次渲染时都会被执行。
		当你需要基于现有数据创建一个新的派生数据，或者需要在数据变化时自动更新视图时，计算属性是非常有用的工具。它们简化了代码结构，提高了代码的可读性和维护性。
		*/
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ projectsHeading }}
			</p>
		</div>

		<!-- Filter and search projects -->
		<div class="mt-10 sm:mt-10">
			<h3
				class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				"
			>
				Search projects by title or filter by category
			</h3>
			<div
				class="
					flex
					justify-between
					border-b border-primary-light
					dark:border-secondary-dark
					pb-3
					gap-2
				"
			>
				<div class="flex justify-between gap-2">
					<span
						class="
							hidden
							sm:block
							bg-primary-light
							dark:bg-ternary-dark
							p-2.5
							shadow-sm
							rounded-xl
							cursor-pointer
							"
					>
						<i
							data-feather="search"
							class="text-ternary-dark dark:text-ternary-light"
						></i>
					</span>
					<!-- 
						在Vue.js中，v-model是一个指令，用于实现数据的双向绑定。它在表单控件如input、select、textarea等元素上使用，将用户界面的输入值与Vue实例的数据属性关联起来。
					-->
					<input					
						v-model="searchProject"						
						class="font-general-medium
						pl-3
						pr-1
						sm:px-4
						py-2
						border-1 border-gray-200
						dark:border-secondary-dark
						rounded-lg
						text-sm
						sm:text-md
						bg-secondary-light
						dark:bg-ternary-dark
						text-primary-dark
						dark:text-ternary-light
						"
						id="name"
						name="name"
						type="search"
						required=""
						placeholder="Search Projects"
						aria-label="Name"
					/>
				</div>
				<ProjectsFilter @filter="selectedCategory = $event" />
			</div>
		</div>

		<!-- Projects grid -->
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<ProjectSingle
				v-for="project in filteredProjects"
				:key="project.id"
				:project="project"
			/>
		</div>
	</section>
</template>

<style scoped></style>
