<script>
export default {
	props: {	// Button的属性，由上级父组件传递数据
		title: {
			type:String,
			default: "default title"
		}
	},
	data: () => {
		return {
			//
		};
	},
};
</script>

<template>
	<button>{{ title }}</button>
</template>

<style lang="scss" scoped></style>
